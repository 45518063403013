import React, { useState, useRef, useEffect } from "react";
import Card from "./Card";
import CardList from "./CardList";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import Preloader from "../loadinganimations/Preloader";
gsap.registerPlugin(Draggable);

const Horizontal = ({ onMouseEnter, onMouseLeave }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const [displayMode, setDisplayMode] = useState("grid");

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 900);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [cards, setCards] = useState([
    {
      path: "/home/justdance",
      src: "/justdancecope.png",
      title: "JUSTDANCE 23",
      description: "Farfalle Italian Launch",
      year: "2022",
      className: "border",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
        { name: "Tiktok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/monnier",
      name: "monnier",
      src: "/monnier.png",
      title: "MONNIER PARIS",
      description: "Digital Marketing",
      year: "2022 - Today",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Tiktok", slug: "tiktok" },
      ],
      className: "border2",
    },
    {
      path: "/home/discovery",
      name: "Discovery",
      src: "/DISCOVERY.png",
      title: "DISCOVERY+",
      description: "Tokyo 2020",
      year: "2021",
      categories: [{ name: "Media", slug: "media" }],
      category: "media",
    },
    {
      path: "/home/gi-group-holding",
      src: "/holding.png",
      title: "GI GROUP HOLDING",
      description: "Digital Design and Video Production",
      year: "2022 - Today",
      className: "border2",
      categories: [{ name: "Creative", slug: "creative" }],
    },
    {
      path: "/home/watchdogs-legion",
      src: "/WATCHDOGS.png",
      title: "WATCHDOGS LEGION",
      description: "Milano Resiste",
      year: "2020",
      className: "border",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/u-mask",
      src: "/U-MASK.png",
      title: "U-MASK",
      description: "Digital Desig & Media Planning",
      year: "2019",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/msa-mizar",
      src: "/MSA_MIZAR.png",
      title: "MSA MIZAR",
      description: "Brand & Communication Management",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/mahmood",
      src: "/MAHMOOD.png",
      title: "MAHMOOD",
      description: "Social Strategy & Management",
      year: "2019",
      categories: [{ name: "Social", slug: "social" }],
    },
    {
      path: "/home/noemi",
      src: "/NOEMI.png",
      title: "NOEMI",
      description: "Type Design and Web Development",
      year: "2021",
      categories: [{ name: "Creative", slug: "creative" }],
    },
    {
      path: "/home/ubisoft-italia",
      src: "/UBISOFT.png",
      title: "UBISOFT ITALIA",
      description: "Tik Tok Strategy & Management",
      year: "2020 - Today",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/milestone",
      src: "/MILESTONE.png",
      title: "MILESTONE",
      description: "Tik Tok Strategy & Management",
      year: "2022 - Today",
      category: "media",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/u-earth",
      src: "/U-EARTH.png",
      title: "U-EARTH",
      description: "Digital Design & Media Planning",
      year: "2022",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/zweb-tv",
      src: "/ZWEB.png",
      title: "ZWEB-TV",
      description: "Digital Marketing",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/paola-torrente",
      src: "/SPILLIN.png",
      title: "SPILLIN' BY PAOLA TORRENTE",
      description: "Digital Design & Strategy",
      year: "2022",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/vr46-metaverse",
      src: "/VR46.png",
      title: "VR46 METAVERSE",
      description: "Digital Strategy & Management",
      year: "2022",
      categories: [{ name: "Social", slug: "social" }],
    },
  ]);

  const [selectedFilter, setSelectedFilter] = useState("all");

  const handleFilterButtonClick = (filter) => {
    setSelectedFilter(filter);
  };

  let headingText = "All Works";
  switch (selectedFilter) {
    case "media":
      headingText = "Media Center";
      break;
    case "creative":
      headingText = "Creative Agency";
      break;
    case "social":
      headingText = "Social Unit";
      break;
    case "tiktok":
      headingText = "TikTok Hub";
      break;
    default:
      headingText = "All Works";
  }

  const firstWord = headingText.split(" ")[0]; // Extract the first word

  const filteredCards =
    selectedFilter === "all"
      ? cards
      : cards.filter((card) =>
          card.categories.some((category) => category.slug === selectedFilter)
        );

  const swiperRef = useRef(null);

  return (
    <>
      <Preloader />
      {isMobile ? (
        <div>
          <div
            className="worksmobile"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 20px",
              marginTop: "120px",
            }}
          >
            <h2
              style={{ color: "#FE322E", fontSize: "32px", fontWeight: "300" }}
            >
              Selected{" "}
              <span style={{ fontFamily: "GT Light Italic" }}>Works </span>
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
                VIEW:
              </h4>
              <button
                autoFocus
                onClick={() => setDisplayMode("grid")}
                className="filterbtn"
                style={{ padding: "5px" }}
              >
                Grid
              </button>
              <button
                onClick={() => setDisplayMode("list")}
                className="filterbtn"
                style={{ padding: "5px" }}
              >
                List
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div
            className="mobileline"
            style={{
              height: "0.5px ",
              backgroundColor: "#FE322E",
              margin: "0 20px",
              textAlign: "center",
            }}
          ></div>
          {displayMode === "grid" ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="line-vertical"></div>
              <div className="line-horizontal"></div>
              {filteredCards.map((card, index) => (
                <div
                  className="carddiv"
                  key={index}
                  style={{ width: "50%", cursor: "pointer" }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to={card.path}
                  >
                    <Card
                      src={card.src}
                      title={card.title}
                      description={card.description}
                      year={card.year}
                      className={card.className}
                      id={card.name}
                      path={cards.path}
                    />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {filteredCards.map((card, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    margin: "0 !important",
                    padding: "0 !important",
                  }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to={card.path}
                  >
                    <CardList
                      title={card.title}
                      description={card.description}
                      year={card.year}
                      className={card.className}
                      id={card.name}
                      path={cards.path}
                    />
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="height-div" style={{ height: "77vh" }}>
          <div
            className="filters"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "70px",
              margin: "0 25px",
              marginTop: "100px",
            }}
          >
            <div>
              <h2
                style={{
                  color: "#FE322E",
                  fontSize: "55px",
                  fontWeight: "300",
                }}
              >
                <span style={{ fontFamily: "GT Light Italic" }}>
                  {firstWord}
                </span>{" "}
                {headingText.substring(firstWord.length)}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontFamily: "GT Super Display Light",
                }}
              >
                <h4
                  style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}
                >
                  FILTER:
                </h4>
                <button
                  onClick={() => handleFilterButtonClick("all")}
                  autoFocus
                  id="allworkbutton"
                  className={`filterbtn ${
                    selectedFilter === "all" ? "active" : ""
                  }`}
                >
                  All Works
                </button>
                <button
                  className={`filterbtn ${
                    selectedFilter === "media" ? "active" : ""
                  }`}
                  onClick={() => handleFilterButtonClick("media")}
                >
                  Media
                </button>
                <button
                  className={`filterbtn ${
                    selectedFilter === "creative" ? "active" : ""
                  }`}
                  onClick={() => handleFilterButtonClick("creative")}
                >
                  Creative
                </button>
                <button
                  className={`filterbtn ${
                    selectedFilter === "social" ? "active" : ""
                  }`}
                  onClick={() => handleFilterButtonClick("social")}
                >
                  Social
                </button>
                <button
                  className={`filterbtn ${
                    selectedFilter === "tiktok" ? "active" : ""
                  }`}
                  onClick={() => handleFilterButtonClick("tiktok")}
                >
                  TikTok
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              color: "red",
              background: "red",
              marginBottom: "28px",
              marginTop: "10px",
            }}
          ></div>
          <div>
            <Swiper
              className="swiper-container"
              slidesPerView={3}
              spaceBetween={10}
              onSlideChange={(swiper) => {
                gsap.set(swiper.slides, { scale: 1 });

                // Scale up the center slide
                gsap.to(swiper.slides[swiper.activeIndex + 1], { scale: 1.05 });
              }}
            >
              {filteredCards.map((card, index) => (
                <SwiperSlide className="slide-new" key={index}>
                  <Link style={{ textDecoration: "none" }} to={card.path}>
                    <div
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      style={{
                        flexShrink: "0",
                        margin: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={card.src}
                        alt={card.title}
                        style={{ width: "100%" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h3
                            style={{
                              fontSize: "18px",
                              fontFamily: "Neutria Light",
                              color: "#575757",
                            }}
                          >
                            {card.title}
                          </h3>
                          <p
                            style={{
                              fontFamily: "GT Light Italic",
                              fontSize: "16px",
                              color: "#FE322E",
                              marginLeft: "10px",
                            }}
                          >
                            {card.description}
                          </p>
                        </div>
                        <p
                          style={{
                            fontFamily: "Neutria Regular",
                            color: "#575757",
                          }}
                        >
                          {card.year}
                        </p>
                      </div>
                      {/* Render other card contents here */}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default Horizontal;
