import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import animationData from "../animations/unica.json";
import lottie from "lottie-web";
import animationDataMobile from "../animations/mobileunica.json";
import Preloader from "../loadinganimations/Preloader";

import Header from "./Header";
import Home from "./Home";
import GsapTextAnimation from "./GsapTextAnimation";

function Method() {
  const lottieRef = useRef(null);
  const lottieRefMobile = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimationMobile, setShowAnimationMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const navigate = useNavigate();

  useEffect(() => {
    const animDuration = 5000;
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: isMobile ? animationDataMobile : animationData,
    });

    function animateBodymovin(duration) {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const maxFrames = anim.totalFrames;
      const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));

      if (scrollPosition > 0 && !showAnimation) {
        anim.playSegments([0, frame], true);
        setShowAnimation(true);
      } else {
        anim.goToAndStop(frame, true);
      }

      // Check if the animation has reached the end and navigate to home
      if (frame >= maxFrames) {
        navigate("/cta-method");
      }

      if (scrollPosition === 0) {
        setShowAnimation(false);
      }
    }

    function handleScroll() {
      animateBodymovin(animDuration);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      anim.destroy();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile, navigate, showAnimation]);

  //animation part for mobile

  // code for resizing mobile

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 900);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <Preloader />
      <div>
        {isMobile ? (
          <div style={{ height: "8000px" }}>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "0",
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 !Important",
              }}
              ref={lottieRef}
            ></div>
          </div>
        ) : (
          <div style={{ height: "6000px" }}>
            <div
              style={{
                position: "fixed",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            ></div>

            <div
              style={{
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "0",
                width: "100%",
              }}
              ref={lottieRef}
            ></div>
          </div>
        )}
      </div>
    </>
  );
}

export default Method;
