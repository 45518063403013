import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "../animations/startingnew.json";
import animationDataMobile from "../animations/initialmobile.json";
import { useNavigate } from "react-router-dom";
import Home from "./Home";

function LottieScrollAnimation() {
  const lottieRef = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 900;

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: isMobile ? animationDataMobile : animationData,
    });

    anim.addEventListener("complete", () => {
      setShowAnimation(true);
      navigate("/home");
    });

    return () => {
      anim.destroy();
    };
  }, [navigate, isMobile]);

  return (
    <>
      {!showAnimation && (
        <div
          style={{
            position: "fixed",
            zIndex: "9999",
            ...(isMobile
              ? {
                  width: "100%",
                }
              : {
                  // Desktop styles
                  top: "0",
                  left: "0",
                  right: "0",
                  left: "0",
                }),
          }}
          ref={lottieRef}
        ></div>
      )}
      {showAnimation && <Home />}
    </>
  );
}

export default LottieScrollAnimation;
