import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Card from "./Card";
import CardList from "./CardList";
import Preloader from "../loadinganimations/Preloader";

// Import the cards array from Home.js
import Home from "./Home";

export const cards = [
  {
    name: "justdance",
    src: "/justdance.png",
    title: "JUSTDANCE 23",
    description: "Description 3",
    year: "2022",
    className: "border",
    category: "media",
    path: "/justdance",
  },
  {
    name: "monnier",
    src: "/monnier.png",
    title: "MONNIER PARIS",
    description: "Description 3",
    year: "2015",
    category: "social",
    className: "border2",
  },
  {
    name: "holding",
    src: "/holding.png",
    title: "Title 3",
    description: "Description 3",
    year: "2017",
    className: "border",
    category: "creative",
  },
  {
    src: "/monnier.png",
    title: "Title 3",
    description: "Description 3",
    year: "2018",
    className: "border2",
    category: "media",
  },
  {
    src: "/maiale.png",
    title: "Title 3",
    description: "Description 3",
    year: "2016",
    className: "border",
    category: "social",
  },
  {
    src: "/monnier.png",
    title: "Title 3",
    description: "Description 3",
    year: "2019",
    category: "tiktok",
  },
];

function Holding() {
  const [mainVideo, setMainVideo] = useState("/gigroup1.mp4");
  const [sortOrder, setSortOrder] = useState("none");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [sortByYear, setSortByYear] = useState(false);
  const location = useLocation();
  const [activeVideo, setActiveVideo] = useState("/gigroup1.mp4");
  const [yearFilter, setYearFilter] = useState("all");
  const [displayMode, setDisplayMode] = useState("grid");
  const isMobile = window.innerWidth < 900;

  const [words, setWords] = useState([
    "magic",
    "football",
    "React",
    "coding",
    "hacking",
  ]);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentWordIndex((prevIndex) => {
        if (prevIndex === words.length - 1) {
          setTimeout(() => setCurrentWordIndex(0), 500);
          return prevIndex;
        } else {
          return prevIndex + 1;
        }
      });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [currentWordIndex, words.length]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sortCards = (order) => {
    console.log("Sorting cards by year...");
    setSortOrder(order);
    setSortByYear(true);
  };

  const getSuggestedOrder = () => {
    console.log("Displaying cards in suggested order...");
    setSortOrder("none");
    setSortByYear(false);
  };

  const [cards, setCards] = useState([
    {
      path: "/home/msa-mizar",
      src: "/MSA_MIZARdef.jpg",
      title: "MSA MIZAR",
      description: "Brand & Communication Management",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/u-earth",
      src: "/U-EARTHdef.jpg",
      title: "U-EARTH",
      description: "Digital Design & Media Planning",
      year: "2022",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/u-mask",
      src: "/MASKdef.jpg",
      title: "U-MASK",
      description: "Digital Design & Media Planning",
      year: "2019",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/monnier",
      name: "monnier",
      src: "/MONNIERdef.jpg",
      title: "MONNIER PARIS",
      description: "Digital Marketing",
      year: "2022 - today",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Tiktok", slug: "tiktok" },
      ],
      className: "border2",
    },
    {
      path: "/home/justdance",
      src: "/JUSTDANCE23def.jpg",
      title: "JUSTDANCE 23",
      description: "Farfalle Italian Launch",
      year: "2022",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
        { name: "Tiktok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/mahmood",
      src: "/MAHMOODdef.jpg",
      title: "MAHMOOD",
      description: "Social Strategy & Management",
      year: "2019",
      categories: [{ name: "Social", slug: "social" }],
    },
    {
      path: "/home/noemi",
      src: "/NOEMIdef.jpg",
      title: "NOEMI",
      description: "Type Design & Web Development",
      year: "2021",
      categories: [{ name: "Creative", slug: "creative" }],
    },
    {
      path: "/home/paola-torrente",
      src: "/SPILLINdef.jpg",
      title: "SPILLIN' BY PAOLA TORRENTE",
      description: "Digital Design & Strategy",
      year: "2022",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/zweb-tv",
      src: "/ZWEBdef.jpg",
      title: "ZWEB-TV",
      description: "Digital Marketing",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },

    {
      path: "/home/discovery",
      name: "Discovery",
      src: "/DISCOVERYdef.jpg",
      title: "DISCOVERY+",
      description: "Tokyo 2020",
      year: "2021",
      categories: [{ name: "Media", slug: "media" }],
      category: "media",
    },
    {
      path: "/home/watchdogs-legion",
      src: "/WATCHDOGSdef.jpg",
      title: "WATCHDOGS LEGION",
      description: "Milano Resiste",
      year: "2020",
      className: "border",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/ubisoft-italia",
      src: "/UBISOFTdef.jpg",
      title: "UBISOFT ITALIA",
      description: "TikTok Strategy & Management",
      year: "2020 - today",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/milestone",
      src: "/MILESTONEdef.jpg",
      title: "MILESTONE",
      description: "TikTok Strategy & Management",
      year: "2022 - today",
      category: "media",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/vr46-metaverse",
      src: "/VR46def.jpg",
      title: "VR46 METAVERSE",
      description: "Digital Strategy & Management",
      year: "2022",
      categories: [{ name: "Social", slug: "social" }],
    },
  ]);

  let sortedCards = [...cards];
  if (selectedFilter !== "all") {
    sortedCards = sortedCards.filter(
      (card) =>
        card.categories &&
        card.categories.some((category) => category.slug === selectedFilter)
    );
  }
  if (sortOrder === "asc") {
    sortedCards = sortedCards
      .sort((a, b) => a.year.localeCompare(b.year))
      .reverse();
  } else if (sortOrder === "desc") {
    sortedCards = sortedCards.sort((a, b) => a.year.localeCompare(b.year));
  }

  if (yearFilter === "2022-today") {
    sortedCards = sortedCards.filter((card) => {
      const currentYear = new Date().getFullYear().toString();
      return card.year === currentYear || card.year === "2022";
    });
  } else if (yearFilter !== "all") {
    sortedCards = sortedCards.filter((card) => card.year === yearFilter);
  }
  function handleVideoClick(video) {
    setMainVideo(video);
    setActiveVideo(video);
  }

  const handleFilterButtonClick = (filter) => {
    setSelectedFilter(filter);
    updateLineHeight();
  };

  useEffect(() => {
    setTimeout(() => {
      updateLineHeight();
    }, 100);
  }, [selectedFilter, displayMode]);

  const lineRef = useRef(null);
  const divToLineHeightRef = useRef(null);

  const updateLineHeight = () => {
    const divToLineHeightElement = divToLineHeightRef.current;
    const lineElement = lineRef.current;

    if (divToLineHeightElement && lineElement) {
      const divToLineHeightHeight = divToLineHeightElement.clientHeight;
      lineElement.style.height = `${divToLineHeightHeight}px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateLineHeight);
    window.onload = updateLineHeight;

    return () => {
      window.removeEventListener("resize", updateLineHeight);
    };
  }, []);

  return (
    <>
      <Preloader />
      {isMobile ? (
        <div>
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid red",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            <h2
              style={{
                color: "#FE322E",
                fontSize: "35px",
                fontFamily: "GT Light !important",
                fontWeight: "400",
              }}
            >
              Gi Group Holding
            </h2>
            <h2
              style={{
                fontSize: "15px",
                fontFamily: "Neutria Regular",
                fontWeight: "400",
              }}
            >
              2022 - Today
            </h2>
          </div>
          <div style={{ margin: "15px 20px" }}>
            <p style={{ fontSize: "20px", fontFamily: "Neutria Light" }}>
              Video Production, Editing & Motion Graphic
            </p>
            <p
              style={{
                fontSize: "19px",
                color: "#FE322E",
                fontFamily: "GT Light Italic",
              }}
            >
              Corporate Branding
            </p>
          </div>
          <div>
            <img style={{ width: "100%" }} src="/holdingcopemobile.png" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <video
                  poster="/holdingposter1.jpg"
                  style={{ width: "100%" }}
                  src={mainVideo}
                  controls
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "5px",
                }}
              >
                <video
                  poster="/holdingposter1.jpg"
                  style={{
                    marginBottom: "10px",
                    width: "32%",
                    boxSizing: "border-box",
                    border:
                      activeVideo === "/gigroup1.mp4"
                        ? "2px solid #FE322E"
                        : "",
                  }}
                  src="/gigroup1.mp4"
                  onClick={() => handleVideoClick("/gigroup1.mp4")}
                />
                <video
                  poster="/holdingposter2.jpg"
                  style={{
                    marginBottom: "10px",
                    width: "32%",
                    boxSizing: "border-box",
                    border:
                      activeVideo === "/gigroup2.mp4"
                        ? "2px solid #FE322E"
                        : "",
                  }}
                  src="/gigroup2.mp4"
                  onClick={() => handleVideoClick("/gigroup2.mp4")}
                />
                <video
                  poster="/holdingposter3.jpg"
                  style={{
                    width: "32%",
                    boxSizing: "border-box",
                    marginBottom: "10px",
                    border:
                      activeVideo === "/gigroup3.mp4"
                        ? "2px solid #FE322E"
                        : "",
                  }}
                  src="/gigroup3.mp4"
                  onClick={() => handleVideoClick("/gigroup3.mp4")}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "20px" }}>
            <h2
              style={{
                fontFamily: "GT Light !Important",
                fontWeight: "400",
                fontSize: "32px",
                color: "#FE322E",
                margin: "20px 0",
              }}
            >
              Developing a consistent{" "}
              <span style={{ fontFamily: "GT Light Italic" }}>
                Corporate image
              </span>
            </h2>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Neutria Light",
                fontWeight: "400 !important",
              }}
            >
              Gi Group relied on our expertise to further develop its 360-degree
              communication project, aiming to establish the brand as the
              worldwide player responsible for creating a sustainable and
              enjoyable Global Labour Market for Candidates and Companies. We
              utilized our video production and motion graphics skills to create
              engaging content that effectively conveyed Gi Group’s values
              proposition and company Credo, with a strong focus on employer
              branding to attract top talent. Additionally, we designed and
              developed a variety of materials that were carefully crafted to be
              coherent with Gi Group’s identity. As part of our activity, we
              managed an on-site video production dedicated to the Holding’s
              rebranding and took charge of video editing to ensure that the
              final product met the highest expectations.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div>
            <img style={{ width: "100%" }} src="/holdingcopedef.png" />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "100px",
                color: "#FE322E",
              }}
            >
              <div
                style={{
                  borderTop: "1px solid #FE322E",
                  borderBottom: "1px solid #FE322E",
                  padding: "10px",
                  width: "14%",
                  textAlign: "left",
                  fontFamily: "Neutria Regular",
                }}
              >
                <p>Gi Group Holding</p>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #FE322E",
                  borderTop: "1px solid #FE322E",
                  borderBottom: "1px solid #FE322E",
                  padding: "10px",
                  width: "30%",
                  textAlign: "center",
                  fontFamily: "Neutria Regular",
                }}
              >
                <p>Video Production, Editing & Motion Graphic</p>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #FE322E",
                  borderTop: "1px solid #FE322E",
                  borderBottom: "1px solid #FE322E",
                  padding: "10px",
                  width: "30%",
                  textAlign: "center",
                  fontFamily: "Neutria Regular",
                }}
              >
                <p>Corporate Branding</p>
              </div>
              <div
                style={{
                  borderLeft: "1px solid #FE322E",
                  padding: "10px",
                  borderTop: "1px solid #FE322E",
                  borderBottom: "1px solid #FE322E",
                  width: "14%",
                  textAlign: "right",
                  fontFamily: "Neutria Regular",
                }}
              >
                <p>2022 - Today</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "flex-start",
              padding: "50px",
            }}
          >
            <div style={{ width: "75%", marginRight: "10px", height: "675px" }}>
              <video
                style={{ width: "100%" }}
                src={mainVideo}
                controls
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "100%",
                marginTop: "5px",
                width: "24.12%",
                marginTop: "-0.2px",
              }}
            >
              <video
                poster="/holdingposter1.jpg"
                style={{
                  marginBottom: "10px",

                  boxSizing: "border-box",
                  border:
                    activeVideo === "/gigroup1.mp4" ? "2px solid #FE322E" : "",
                }}
                src="/gigroup1.mp4"
                onClick={() => handleVideoClick("/gigroup1.mp4")}
              />
              <video
                poster="/holdingposter2.jpg"
                style={{
                  marginBottom: "10px",

                  boxSizing: "border-box",
                  border:
                    activeVideo === "/gigroup2.mp4" ? "2px solid #FE322E" : "",
                }}
                src="/gigroup2.mp4"
                onClick={() => handleVideoClick("/gigroup2.mp4")}
              />
              <video
                poster="/holdingposter3.jpg"
                style={{
                  boxSizing: "border-box",
                  marginBottom: "10px",
                  border:
                    activeVideo === "/gigroup3.mp4" ? "2px solid #FE322E" : "",
                }}
                src="/gigroup3.mp4"
                onClick={() => handleVideoClick("/gigroup3.mp4")}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                background: "white",
                padding: "35px 20px",
                display: "flex",
                width: "700px",
                marginLeft: "40px",
              }}
            >
              <h2
                style={{
                  fontSize: "75px",
                  color: "#FE322E",
                  maxWidth: "550px",
                  fontWeight: "500",
                  margin: "0 !important",
                  lineHeight: "75px",
                  letterSpacing: "-2.5px",
                }}
              >
                Developing <br />a consistent{" "}
                <span style={{ fontFamily: "GT Light Italic" }}>
                  Corporate Image
                </span>{" "}
              </h2>
            </div>
            <div>
              <p
                style={{
                  maxWidth: "600px",
                  fontFamily: "Neutria Light",
                  marginRight: "182px",
                }}
              >
                Gi Group relied on our expertise to further develop its
                360-degree communication project, aiming to establish the brand
                as the worldwide player responsible for creating a sustainable
                and enjoyable Global Labour Market for Candidates and Companies.
                We utilized our video production and motion graphics skills to
                create engaging content that effectively conveyed Gi Group’s
                values proposition and company Credo, with a strong focus on
                employer branding to attract top talent. Additionally, we
                designed and developed a variety of materials that were
                carefully crafted to be coherent with Gi Group’s identity. As
                part of our activity, we managed an on-site video production
                dedicated to the Holding’s rebranding and took charge of video
                editing to ensure that the final product met the highest
                expectations.
              </p>
            </div>
          </div>
        </>
      )}
      <div
        className="filters"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
          margin: "0 25px",
          marginTop: "100px",
        }}
      >
        <div>
          <h2 style={{ color: "#FE322E", fontSize: "40px", fontWeight: "300" }}>
            Selected
            <span className="italic">Works </span>
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ display: "flex", fontFamily: "GT Super Display Light" }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              FILTER:
            </h4>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("all")}
              autoFocus
            >
              All Work
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("media")}
            >
              Media
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("creative")}
            >
              Creative
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("social")}
            >
              Social
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("tiktok")}
            >
              TikTok
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              SORT BY:
            </h4>
            <button className="filterbtn" onClick={() => sortCards("asc")}>
              Year
            </button>
            <button
              autoFocus
              className="filterbtn"
              onClick={() => getSuggestedOrder()}
            >
              Suggested
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              VIEW:
            </h4>
            <button
              onClick={() => setDisplayMode("grid")}
              className="filterbtn"
            >
              Grid
            </button>
            <button
              onClick={() => setDisplayMode("list")}
              className="filterbtn"
            >
              {" "}
              List
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          className="worksmobile"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 20px",
          }}
        >
          <h2 style={{ color: "#FE322E", fontSize: "32px", fontWeight: "300" }}>
            Related <span className="italic">Works </span>
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              VIEW:
            </h4>
            <button
              autoFocus
              onClick={() => setDisplayMode("grid")}
              className="filterbtn"
              style={{ padding: "5px" }}
            >
              Grid
            </button>
            <button
              onClick={() => setDisplayMode("list")}
              className="filterbtn"
              style={{ padding: "5px" }}
            >
              {" "}
              List
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          className="mobileline"
          style={{
            height: "0.5px ",
            backgroundColor: "#FE322E",
            margin: "0 20px",
            textAlign: "center",
          }}
        ></div>
      </div>

      {displayMode === "grid" ? (
        <div
          ref={divToLineHeightRef}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div ref={lineRef} className="line-vertical"></div>
          <div className="line-horizontal"></div>
          {sortedCards.map((card, index) => (
            <div
              className="carddiv"
              key={index}
              style={{ width: "50%", cursor: "pointer" }}
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={card.path}
              >
                <Card
                  src={card.src}
                  title={card.title}
                  description={card.description}
                  year={card.year}
                  className={card.className}
                  id={card.name}
                  path={cards.path}
                />
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {sortedCards.map((card, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                cursor: "pointer",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={card.path}
              >
                <CardList
                  title={card.title}
                  description={card.description}
                  year={card.year}
                  className={card.className}
                  id={card.name}
                  path={cards.path}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Holding;
