import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import lottie from "lottie-web";
import animationDataMobile1 from "../animations/cubomobile.json";
import animationDataMobile2 from "../animations/atterraggiomobile.json";

function AnimationsMobile() {
  const lottieRef3 = useRef(null);
  const lottieRef4 = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimation2, setShowAnimation2] = useState(false);
  const [displayMode, setDisplayMode] = useState("grid");

  useEffect(() => {
    const anim4 = lottie.loadAnimation({
      container: lottieRef3.current,
      renderer: "svg",
      loop: true,
      autoplay: true, // set autoplay to true
      animationData: animationDataMobile2,
    });

    return () => {
      // Remove the scroll event listener when the component unmounts

      anim4.destroy();
    };
  }, []);

  useEffect(() => {
    const animDuration = 2000;
    const anim3 = lottie.loadAnimation({
      container: lottieRef4.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationDataMobile1,
    });

    function animateBodymovin(duration) {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const maxFrames = anim3.totalFrames;
      const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));

      if (scrollPosition > 0 && !showAnimation) {
        anim3.playSegments([0, frame], true);
        setShowAnimation(true);
      } else {
        anim3.goToAndStop(frame, true);
      }

      if (scrollPosition === 0) {
        setShowAnimation(false);
      }
    }

    function handleScroll() {
      animateBodymovin(animDuration);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts

      anim3.destroy();

      window.removeEventListener("scroll", handleScroll);
    };
  }, [showAnimation]);

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999999999",
          }}
          ref={lottieRef3}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "800px",
        }}
      >
        <img style={{ width: "300px" }} src="/senzacta.png" />
        <Link style={{ textDecoration: "none" }} to="/method">
          <button className="cta-button">Discover How</button>
        </Link>
      </div>
    </div>
  );
}

export default AnimationsMobile;
