import React from "react";
import { useCallback } from "react";
import LottieScrollAnimation from "./components/LottieScrollAnimation";
import Justdance from "./components/Justdance";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Header from "./components/Header";
import Horizontal from "./components/Horizontal";
import Contacts from "./components/Contacts";
import Method from "./components/Method";
import JobApplication from "./components/JobApplication";
import Monnier from "./components/Monnier";
import Spillin from "./components/Spillin";
import Glicine from "./components/Glicine";
import Holding from "./components/Holding";
import Discovery from "./components/Discovery";
import Mahmood from "./components/Mahmood";
import Vr46 from "./components/Vr46";
import Uearth from "./components/Uearth";
import Msa from "./components/Msa";
import Ubisoft from "./components/Ubisoft";
import Milestone from "./components/Milestone";
import Zweb from "./components/Zweb";
import Umask from "./components/Umask";
import Watchdogs from "./components/Watchdogs";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Preloader from "./loadinganimations/Preloader";
import "./App.css";
import MethodCta from "./components/MethodCta";

function App() {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  console.log(mousePosition);

  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x,
      y: mousePosition.y,
      backgroundColor: "#FE322E",
    },
    text: {
      scale: 6,
      x: mousePosition.x,
      y: mousePosition.y,
      content: "DRAG",
      backgroundColor: "#FE322E",
    },
    white: {
      x: mousePosition.x,
      y: mousePosition.y,
      backgroundColor: "white",
    },
  };

  useEffect(() => {
    setCursorVariant("default");
  }, [window.location.pathname]);

  function useAnimatedCursor(setCursorVariant) {
    const handleMouseEnter = useCallback(() => {
      setCursorVariant("text");
    }, [setCursorVariant]);

    const handleMouseLeave = useCallback(() => {
      setCursorVariant("default");
    }, [setCursorVariant]);

    const handleMouseEnterWhite = useCallback(() => {
      setCursorVariant("white");
    }, [setCursorVariant]);

    const handleMouseLeaveWhite = useCallback(() => {
      setCursorVariant("default");
    }, [setCursorVariant]);

    return [
      handleMouseEnter,
      handleMouseLeave,
      handleMouseEnterWhite,
      handleMouseLeaveWhite,
    ];
  }

  const [
    handleMouseEnter,
    handleMouseLeave,
    handleMouseEnterWhite,
    handleMouseLeaveWhite,
  ] = useAnimatedCursor(setCursorVariant);

  return (
    <BrowserRouter>
      <Header
        handleMouseEnterWhite={handleMouseEnterWhite}
        handleMouseLeaveWhite={handleMouseLeaveWhite}
      />
      <motion.div
        className="cursor"
        variants={variants}
        animate={cursorVariant}
      >
        {" "}
        {cursorVariant === "text" && (
          <p
            style={{
              fontSize: "1px !important",
              fontFamily: "Neutria Light",
              color: "white",
              marginBottom: "-1px",
            }}
          >
            {variants.text.content}
          </p>
        )}
      </motion.div>

      <Routes>
        <Route exact path="/" element={<LottieScrollAnimation />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/home/justdance" element={<Justdance />} />
        <Route path="/home/monnier" element={<Monnier />} />
        <Route path="/home/noemi" element={<Glicine />} />
        <Route path="/home/gi-group-holding" element={<Holding />} />
        <Route path="/home/vr46-metaverse" element={<Vr46 />} />
        <Route path="/home/msa-mizar" element={<Msa />} />
        <Route path="/home/ubisoft-italia" element={<Ubisoft />} />
        <Route path="/home/milestone" element={<Milestone />} />
        <Route path="/home/zweb-tv" element={<Zweb />} />
        <Route path="/home/u-mask" element={<Umask />} />
        <Route path="/home/watchdogs-legion" element={<Watchdogs />} />
        <Route path="/home/discovery" element={<Discovery />} />
        <Route path="/home/mahmood" element={<Mahmood />} />
        <Route path="/home/u-earth" element={<Uearth />} />

        <Route path="/home/paola-torrente" element={<Spillin />} />

        <Route
          path="/selected-works"
          element={
            <Horizontal
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          }
        />

        <Route path="/contacts" element={<Contacts />} />
        <Route path="/method" element={<Method />} />
        <Route path="/Jobs" element={<JobApplication />} />
        <Route path="/cta-method" element={<MethodCta />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
