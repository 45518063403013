import React, { useState, useEffect, useRef } from "react";
import Card from "./Card";
import { Link, useNavigate, useLocation } from "react-router-dom";
import animationData from "../animations/ANIMAZIONE-CUBO.json";
import animationData2 from "../animations/atterraggio.json";

import lottie from "lottie-web";
import CardList from "./CardList";
import AnimationsMobile from "./AnimationsMobile";
import Preloader from "../loadinganimations/Preloader";

function Home() {
  const [sortOrder, setSortOrder] = useState("none");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [sortByYear, setSortByYear] = useState(false);
  const location = useLocation();
  const lottieRef = useRef(null);
  const lottieRef2 = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimation2, setShowAnimation2] = useState(false);
  const [displayMode, setDisplayMode] = useState("grid");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [yearFilter, setYearFilter] = useState("all");

  const isMobile = window.innerWidth < 800;

  useEffect(() => {
    const anim2 = lottie.loadAnimation({
      container: lottieRef2.current,
      renderer: "svg",
      loop: true,
      autoplay: true, // set autoplay to true
      animationData: animationData2,
    });
    return () => {
      // Remove the scroll event listener when the component unmounts

      anim2.destroy();
    };
  }, []);

  useEffect(() => {
    const animDuration = 1200;
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData,
    });

    function animateBodymovin(duration) {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const maxFrames = anim.totalFrames;
      const frame = (maxFrames / 100) * (scrollPosition / (duration / 100));

      if (scrollPosition > 0 && !showAnimation) {
        anim.playSegments([0, frame], true);
        setShowAnimation(true);
      } else {
        anim.goToAndStop(frame, true);
      }

      if (scrollPosition === 0) {
        setShowAnimation(false);
      }
    }

    window.addEventListener("scroll", () => {
      animateBodymovin(animDuration);
    });

    return () => {
      // Remove the scroll event listener when the component unmounts

      anim.destroy();
    };
  }, [showAnimation]);

  const [words, setWords] = useState([
    "closed",
    "action",
    "sugar",
    "cluttered",
    "quack",
    "trousers",
    "fixed",
    "dislike",
    "childlike",
    "seal",
    "weak",
    "useful",
    "RERUN",
    "OPTIONS",
    "SHARE",
  ]);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentWordIndex((prevIndex) => {
        if (prevIndex === words.length - 1) {
          setTimeout(() => setCurrentWordIndex(0), 500);
          return prevIndex;
        } else {
          return prevIndex + 1;
        }
      });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [currentWordIndex, words.length]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sortCards = (order) => {
    console.log("Sorting cards by year...");
    setSortOrder(order);
    setSortByYear(true);
    updateLineHeight();
  };

  const getSuggestedOrder = () => {
    console.log("Displaying cards in suggested order...");
    setSortOrder("none");
    setSortByYear(false);
    updateLineHeight();
  };

  const [cards, setCards] = useState([
    {
      path: "/home/justdance",
      src: "/JUSTDANCE23def.jpg",
      title: "JUSTDANCE 23",
      description: "Farfalle Italian Launch",
      year: "2022",
      className: "border",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
        { name: "Tiktok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/monnier",
      name: "monnier",
      src: "/MONNIERdef.jpg",
      title: "MONNIER PARIS",
      description: "Digital Marketing",
      year: "2022 - Today",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Tiktok", slug: "tiktok" },
      ],
      className: "border2",
    },
    {
      path: "/home/discovery",
      name: "Discovery",
      src: "/DISCOVERYdef.jpg",
      title: "DISCOVERY+",
      description: "Tokyo 2020",
      year: "2021",
      categories: [{ name: "Media", slug: "media" }],
      category: "media",
    },
    {
      path: "/home/gi-group-holding",
      src: "/GIGROUPdef.jpg",
      title: "GI GROUP HOLDING",
      description: "Digital Design & Video Production",
      year: "2022 - Today",
      className: "border2",
      categories: [{ name: "Creative", slug: "creative" }],
    },
    {
      path: "/home/watchdogs-legion",
      src: "/WATCHDOGSdef.jpg",
      title: "WATCHDOGS LEGION",
      description: "Milano Resiste",
      year: "2020",
      className: "border",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/u-mask",
      src: "/MASKdef.jpg",
      title: "U-MASK",
      description: "Digital Design & Media Planning",
      year: "2019",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/msa-mizar",
      src: "/MSA_MIZARdef.jpg",
      title: "MSA MIZAR",
      description: "Brand & Communication Management",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/mahmood",
      src: "/MAHMOODdef.jpg",
      title: "MAHMOOD",
      description: "Social Strategy & Management",
      year: "2019",
      categories: [{ name: "Social", slug: "social" }],
    },
    {
      path: "/home/noemi",
      src: "/NOEMIdef.jpg",
      title: "NOEMI",
      description: "Type Design & Web Development",
      year: "2021",
      categories: [{ name: "Creative", slug: "creative" }],
    },
    {
      path: "/home/ubisoft-italia",
      src: "/UBISOFTdef.jpg",
      title: "UBISOFT ITALIA",
      description: "TikTok Strategy & Management",
      year: "2020 - Today",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/milestone",
      src: "/MILESTONEdef.jpg",
      title: "MILESTONE",
      description: "TikTok Strategy & Management",
      year: "2022 - Today",
      category: "media",
      categories: [
        { name: "Media", slug: "media" },

        { name: "TikTok", slug: "tiktok" },
      ],
    },
    {
      path: "/home/u-earth",
      src: "/U-EARTHdef.jpg",
      title: "U-EARTH",
      description: "Digital Design & Media Planning",
      year: "2022",
      categories: [
        { name: "Media", slug: "media" },
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/zweb-tv",
      src: "/ZWEBdef.jpg",
      title: "ZWEB-TV",
      description: "Digital Marketing",
      year: "2020",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/paola-torrente",
      src: "/SPILLINdef.jpg",
      title: "SPILLIN' BY PAOLA TORRENTE",
      description: "Digital Design & Strategy",
      year: "2022",
      categories: [
        { name: "Creative", slug: "creative" },
        { name: "Social", slug: "social" },
      ],
    },
    {
      path: "/home/vr46-metaverse",
      src: "/VR46def.jpg",
      title: "VR46 METAVERSE",
      description: "Digital Strategy & Management",
      year: "2022",
      categories: [{ name: "Social", slug: "social" }],
    },
  ]);

  let sortedCards = [...cards];
  if (selectedFilter !== "all") {
    sortedCards = sortedCards.filter(
      (card) =>
        card.categories &&
        card.categories.some((category) => category.slug === selectedFilter)
    );
  }
  if (sortOrder === "asc") {
    sortedCards = sortedCards
      .sort((a, b) => a.year.localeCompare(b.year))
      .reverse();
  } else if (sortOrder === "desc") {
    sortedCards = sortedCards.sort((a, b) => a.year.localeCompare(b.year));
  }

  if (yearFilter === "2022-Today") {
    sortedCards = sortedCards.filter((card) => {
      const currentYear = new Date().getFullYear().toString();
      return card.year === currentYear || card.year === "2022";
    });
  } else if (yearFilter !== "all") {
    sortedCards = sortedCards.filter((card) => card.year === yearFilter);
  }

  const handleFilterButtonClick = (filter) => {
    setSelectedFilter(filter);
    updateLineHeight();
  };

  const handleGrid = () => {
    setDisplayMode("grid");
    updateLineHeight();
  };

  useEffect(() => {
    setTimeout(() => {
      updateLineHeight();
    }, 300); // Adjust the delay time as needed (e.g., 100ms)
  }, [selectedFilter, displayMode]);

  const lineRef = useRef(null);
  const divToLineHeightRef = useRef(null);

  const updateLineHeight = () => {
    const divToLineHeightElement = divToLineHeightRef.current;
    const lineElement = lineRef.current;

    if (divToLineHeightElement && lineElement) {
      const divToLineHeightHeight = divToLineHeightElement.clientHeight;
      lineElement.style.height = `${divToLineHeightHeight}px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateLineHeight);
    window.onload = updateLineHeight;

    return () => {
      window.removeEventListener("resize", updateLineHeight);
    };
  }, [selectedFilter, displayMode]);

  return (
    <div>
      {isMobile ? (
        <AnimationsMobile />
      ) : (
        <>
          <div
            className="firstani"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "0",
              }}
              ref={lottieRef2}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "800px",
            }}
          >
            <img style={{ width: "600px" }} src="/senzacta.png" />
            <Link style={{ textDecoration: "none" }} to="/method">
              {" "}
              <button style={{ cursor: "pointer" }} className="cta-button">
                Discover How
              </button>
            </Link>
          </div>
        </>
      )}
      <div
        className="filters"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
          margin: "0 25px",
        }}
      >
        <div>
          <h2 style={{ color: "#FE322E", fontSize: "55px", fontWeight: "300" }}>
            Selected
            <span style={{ marginLeft: "10px" }} className="italic">
              Works{" "}
            </span>
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ display: "flex", fontFamily: "GT Super Display Light" }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              FILTER:
            </h4>
            <button
              onClick={() => handleFilterButtonClick("all")}
              id="allworkbutton"
              className="filterbtn"
            >
              All Work
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("media")}
            >
              Media
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("creative")}
            >
              Creative
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("social")}
            >
              Social
            </button>
            <button
              className="filterbtn"
              onClick={() => handleFilterButtonClick("tiktok")}
            >
              TikTok
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              SORT BY:
            </h4>
            <button className="filterbtn" onClick={() => sortCards("asc")}>
              Year
            </button>
            <button
              id="suggested"
              className="filterbtn"
              onClick={() => getSuggestedOrder()}
              autoFocus
            >
              Suggested
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              VIEW:
            </h4>
            <button
              id="gridButton"
              className="filterbtn"
              onClick={() => handleGrid()}
            >
              Grid
            </button>
            <button
              onClick={() => setDisplayMode("list")}
              className="filterbtn"
            >
              List
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          className="worksmobile"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 20px",
          }}
        >
          <h2 style={{ color: "#FE322E", fontSize: "32px", fontWeight: "300" }}>
            Selected{" "}
            <span style={{ fontFamily: "GT Light Italic" }}>Works </span>
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <h4 style={{ fontFamily: "Neutria Regular", fontWeight: "200" }}>
              VIEW:
            </h4>
            <button
              autoFocus
              onClick={() => setDisplayMode("grid")}
              className="filterbtn"
              style={{ padding: "5px" }}
            >
              Grid
            </button>
            <button
              onClick={() => setDisplayMode("list")}
              className="filterbtn"
              style={{ padding: "5px" }}
            >
              List
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          className="mobileline"
          style={{
            height: "0.5px ",
            backgroundColor: "#FE322E",
            margin: "0 20px",
            textAlign: "center",
          }}
        ></div>
      </div>
      {displayMode === "grid" ? (
        <div
          ref={divToLineHeightRef}
          className="divtolineheight"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div ref={lineRef} className="line-vertical"></div>
          <div className="line-horizontal"></div>
          {sortedCards.map((card, index) => (
            <div
              className="carddiv"
              key={index}
              style={{ width: "50%", cursor: "pointer" }}
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={card.path}
              >
                <Card
                  src={card.src}
                  title={card.title}
                  description={card.description}
                  year={card.year}
                  className={card.className}
                  id={card.name}
                  path={cards.path}
                />
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {sortedCards.map((card, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                cursor: "pointer",
                margin: "0 !important",
                padding: "0 !important",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={card.path}
              >
                <CardList
                  title={card.title}
                  description={card.description}
                  year={card.year}
                  className={card.className}
                  id={card.name}
                  path={cards.path}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Home;
