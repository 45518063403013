import React, { useEffect } from "react";
import { preLoaderAnim } from ".";

function Preloader() {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="preloader">
      <div className="texts-container">
        <img style={{ width: "60px" }} src="/preloadergif.gif" alt="" />
      </div>
    </div>
  );
}

export default Preloader;
