import React, { useState, useCallback, useRef, useEffect } from "react";
import Eyes from "./Eyes";
import "./Header.css";
import TeamPopup from "./TeamPopup";
import { Link, useLocation } from "react-router-dom";

function Header({ handleMouseEnterWhite, handleMouseLeaveWhite }) {
  const [showTeamPopup, setShowTeamPopup] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedImage, setDraggedImage] = useState(null);
  const redScreenRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [mobileMenuText, setMobileMenuText] = useState("MENU"); // new state variable
  const location = useLocation();
  const [showTeamMenu, setShowTeamMenu] = useState(false);
  const teamMenuRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisible] = useState(true);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    setVisible(position < 1500);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = () => {
    setShowScreen(true);
  };

  const handleMobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu); // toggle the state of showMobileMenu
    setMobileMenuText(showMobileMenu ? "MENU" : "CLOSE"); // update the mobileMenuText
  };

  const handleClickedMenuItem = () => {
    setShowMobileMenu(false);
    setMobileMenuText("MENU");
  };

  const handleCloseClick = () => {
    setShowScreen(false);
    handleMouseLeaveWhite();
  };

  const handleTeamClose = () => {
    setShowTeamPopup(false);
  };

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);
      if (draggedImage) {
        const redScreenRect = redScreenRef.current.getBoundingClientRect();
        const x = e.clientX - redScreenRect.left - draggedImage.width / 2;
        const y = e.clientY - redScreenRect.top - draggedImage.height / 2;
        draggedImage.style.left = `${x}px`;
        draggedImage.style.top = `${y}px`;
        setDraggedImage(null);
      }
    },
    [draggedImage]
  );

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    if (draggedImage) {
      setIsDragging(true);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (teamMenuRef.current && !teamMenuRef.current.contains(event.target)) {
        setIsClosing(true);
        setTimeout(() => {
          setShowTeamMenu(false);
          setIsClosing(false);
        }, 300);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [teamMenuRef]);

  const teamMenuClasses = `ani-slide ${isClosing ? "ani-slide-down" : ""}`;

  const menuButtonText = showScreen ? "CLOSE" : "MENU";

  const LocationsForWhiteFolderMenu = ["/jobs", "/cta-method"];

  return (
    <div className="header">
      <Link to="/home">
        <div className="logo">
          <img onClick={handleCloseClick} width={55} src="/logoanimation.gif" />
        </div>
      </Link>
      <div>
        <Eyes />
      </div>
      <div>
        {!isMobile ? (
          <img
            width="40px"
            src={
              LocationsForWhiteFolderMenu.includes(location.pathname)
                ? "/menubianco.png"
                : "/menufolder.png"
            }
            alt=""
            onClick={handleMenuClick}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <button
            onClick={handleMobileMenuClick}
            style={{
              background: "none",
              fontSize: "16px",
              border: "none",
              color: LocationsForWhiteFolderMenu.includes(location.pathname)
                ? showMobileMenu
                  ? "#FE322E"
                  : "#FFFFFF"
                : "#FE322E",
              fontWeight: "500",
              zIndex: "4",
              position: "relative",
              marginRight: "-25px",
            }}
          >
            {mobileMenuText}
          </button>
        )}
      </div>
      {showMobileMenu && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "1",
            background: "white",
            paddingTop: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0 20px",
              textAlign: "left !important",
            }}
          >
            {" "}
            <Link style={{ width: "100%" }} to="/home">
              <button
                onClick={handleClickedMenuItem}
                className="mobile-menu-btn"
                style={{
                  color: location.pathname === "/home" ? "#FE322E" : "black",

                  fontFamily:
                    location.pathname === "/home"
                      ? "GT Light Italic"
                      : "GT Light",

                  borderBottom:
                    location.pathname === "/home"
                      ? "1px solid #FE322E"
                      : "1px solid black",
                }}
              >
                Home
              </button>
            </Link>
            <Link style={{ width: "100%" }} to="/selected-works">
              <button
                style={{
                  color:
                    location.pathname === "/selected-works"
                      ? "#FE322E"
                      : "black",

                  fontFamily:
                    location.pathname === "/selected-works"
                      ? "GT Light Italic"
                      : "GT Light",

                  borderBottom:
                    location.pathname === "/selected-works"
                      ? "1px solid #FE322E"
                      : "1px solid black",
                }}
                onClick={handleClickedMenuItem}
                className="mobile-menu-btn"
              >
                Selected Works
              </button>
            </Link>
            <Link style={{ width: "100%" }} to="/method">
              <button
                style={{
                  color: location.pathname === "/method" ? "#FE322E" : "black",

                  fontFamily:
                    location.pathname === "/method"
                      ? "GT Light Italic"
                      : "GT Light",

                  borderBottom:
                    location.pathname === "/method"
                      ? "1px solid #FE322E"
                      : "1px solid black",
                }}
                onClick={handleClickedMenuItem}
                className="mobile-menu-btn"
              >
                Method
              </button>
            </Link>
            <button
              onClick={() => setShowTeamMenu(!showTeamMenu)}
              className="mobile-menu-btn"
            >
              Team
            </button>
            {showTeamMenu && (
              <div className={teamMenuClasses} ref={teamMenuRef}>
                <div className="header-team">
                  <i
                    style={{
                      color: "white",
                      fontFamily: "'GT Light' !Important",
                      fontWeight: "200",
                      fontSize: "32px",
                      marginLeft: "20px",
                    }}
                  >
                    Team
                  </i>
                  <img
                    style={{ marginBottom: "28px", width: "15px" }}
                    src="/littlearrow.png"
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "15px",
                      color: "white",
                      marginRight: "20px",
                      color: "#FE322E",
                    }}
                  >
                    001 / 015
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginTop: "120px", padding: "0 10px" }}>
                    <img
                      style={{ width: "100%" }}
                      src="/Takingdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Taking it Seriously
                      </i>
                      <p style={{ color: "white" }}>001</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/SHOOT_ALBERTOdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Shoot Alberto
                      </i>
                      <p style={{ color: "white" }}>002</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/SHOOT_ADRIANAdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Shoot Adriana
                      </i>
                      <p style={{ color: "white" }}>003</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/SHOOT_ALESSANDROdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Shoot Alessandro
                      </i>
                      <p style={{ color: "white" }}>004</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/SHOOT_LORENZOdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Shoot Lorenzo
                      </i>
                      <p style={{ color: "white" }}>005</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img style={{ width: "100%" }} src="/Astepdef.jpg" alt="" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        A Step ahead
                      </i>
                      <p style={{ color: "white" }}>006</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/Dont_get_lostdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Don't Get Lost
                      </i>
                      <p style={{ color: "white" }}>007</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img style={{ width: "100%" }} src="/Enjoydef.jpg" alt="" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Enjoy The Moment
                      </i>
                      <p style={{ color: "white" }}>008</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img style={{ width: "100%" }} src="/kissdef.jpg" alt="" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Kiss And Smile
                      </i>
                      <p style={{ color: "white" }}>009</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img style={{ width: "100%" }} src="/npsdef.jpg" alt="" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Nina, Pinta & Santamaria
                      </i>
                      <p style={{ color: "white" }}>010</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/seekingdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Seeking Yourself
                      </i>
                      <p style={{ color: "white" }}>011</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/slowlydef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Slowly but steady
                      </i>
                      <p style={{ color: "white" }}>012</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/teamorfamilydef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Team or family?
                      </i>
                      <p style={{ color: "white" }}>013</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img
                      style={{ width: "100%" }}
                      src="/Threeeyesdef.jpg"
                      alt=""
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Three pairs of eyes
                      </i>
                      <p style={{ color: "white" }}>014</p>
                    </div>
                  </div>
                  <div style={{ padding: "0 10px", marginTop: "35px" }}>
                    <div
                      style={{
                        height: "0.5px",
                        background: "white",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    ></div>
                    <img style={{ width: "100%" }} src="/wishdef.jpg" alt="" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                        paddingBottom: "40px",
                      }}
                    >
                      <i
                        style={{ color: "white", fontWeight: "100 !important" }}
                      >
                        Wish you were
                      </i>
                      <p style={{ color: "white" }}>015</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Link style={{ width: "100%" }} to="/jobs">
              <button
                onClick={handleClickedMenuItem}
                style={{
                  color: location.pathname === "/jobs" ? "#FE322E" : "black",

                  fontFamily:
                    location.pathname === "/jobs"
                      ? "GT Light Italic"
                      : "GT Light",

                  borderBottom:
                    location.pathname === "/jobs"
                      ? "1px solid #FE322E"
                      : "1px solid black",
                }}
                className="mobile-menu-btn"
              >
                Job
              </button>
            </Link>
            <Link style={{ width: "100%" }} to="/contacts">
              <button
                onClick={handleClickedMenuItem}
                style={{
                  color:
                    location.pathname === "/contacts" ? "#FE322E" : "black",

                  fontFamily:
                    location.pathname === "/contacts"
                      ? "GT Light Italic"
                      : "GT Light",

                  borderBottom:
                    location.pathname === "/contacts"
                      ? "1px solid #FE322E"
                      : "1px solid black",
                }}
                className="mobile-menu-btn"
              >
                Contacts
              </button>
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 25px",
              marginTop: "40px",
            }}
          >
            <h2
              style={{
                fontSize: "15px",
                fontFamily: "Neutria Light",
              }}
            >
              WE TRULY <br></br> BE
              <span
                style={{
                  color: "#FE322E",
                  textTransform: "lowercase",
                  fontFamily: "GT Light Italic",
                }}
              >
                LIVE
              </span>{" "}
              IN YOU
            </h2>
            <img width="40px" src="/eyesmobile.png" alt="" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 25px",
              borderTop: "1px solid #FE322E ",
            }}
          >
            <Link to="https://www.linkedin.com/company/livemefive/?originalSubdomain=it">
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                LINKEDIN
              </button>
            </Link>
            <Link to="https://www.instagram.com/livemefive/">
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                INSTAGRAM
              </button>
            </Link>
            <Link to="https://www.behance.net/liveme5ive">
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                BEHANCE
              </button>
            </Link>
          </div>
        </div>
      )}
      {showScreen && (
        <div
          onMouseEnter={handleMouseEnterWhite}
          onMouseLeave={handleMouseLeaveWhite}
          ref={redScreenRef}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            fontFamily: "Neutria Regular",
            fontStyle: "normal",
            fontWeight: "200",
            zIndex: "99999",
          }}
          className="full-screen"
        >
          <div className="red-screen">
            <img
              draggable={true}
              onDragStart={(e) => setDraggedImage(e.target)}
              onDragEnd={() => setIsDragging(false)}
              onClick={() => window.open("/MANIFESTOpdf.pdf")}
              style={{
                position: "absolute",
                right: "120px",
                top: "150px",
                opacity: isDragging ? 0.5 : 1,
                cursor: "pointer",
              }}
              width="100px"
              src="/MANIFESTO.png"
              alt=""
            />
            <Link to="/method">
              <img
                draggable={true}
                onDragStart={(e) => setDraggedImage(e.target)}
                onDragEnd={() => setIsDragging(false)}
                onClick={handleCloseClick}
                style={{
                  position: "absolute",
                  right: "450px",
                  top: "290px",
                  opacity: isDragging ? 0.5 : 1,
                  cursor: "pointer",
                }}
                width="70px"
                src="/METHOD.png"
                alt=""
              />
            </Link>
            <Link to="/contacts">
              <img
                draggable={true}
                onDragStart={(e) => setDraggedImage(e.target)}
                onDragEnd={() => setIsDragging(false)}
                onClick={handleCloseClick}
                style={{
                  position: "absolute",
                  right: "220px",
                  bottom: "50px",
                  opacity: isDragging ? 0.5 : 1,
                  cursor: "pointer",
                }}
                width="70px"
                src="/CONTACT.png"
                alt=""
              />
            </Link>

            <img
              draggable={true}
              onDragStart={(e) => setDraggedImage(e.target)}
              onDragEnd={() => setIsDragging(false)}
              style={{
                position: "absolute",
                left: "170px",
                top: "320px",
                opacity: isDragging ? 0.5 : 1,
                cursor: "pointer",
              }}
              width="70px"
              src="/TEAM.png"
              alt=""
              onClick={() => setShowTeamPopup(true)}
            />
            <TeamPopup
              showTeamPopup={showTeamPopup}
              onClose={handleTeamClose}
            />

            <img
              draggable={true}
              onDragStart={(e) => setDraggedImage(e.target)}
              onDragEnd={() => setIsDragging(false)}
              onClick={() => window.open("https://www.tria.xyz/")}
              style={{
                position: "absolute",
                left: "80px",
                bottom: "20px",
                opacity: isDragging ? 0.5 : 1,
                cursor: "pointer",
              }}
              width="70px"
              src="/TRIA.png"
              alt=""
            />
            <img
              draggable={true}
              onDragStart={(e) => setDraggedImage(e.target)}
              onDragEnd={() => setIsDragging(false)}
              onClick={() => window.open("/BRANDBOOK_LM5.pdf")}
              style={{
                position: "absolute",
                left: "400px",
                bottom: "120px",
                opacity: isDragging ? 0.5 : 1,
                cursor: "pointer",
              }}
              width="120px"
              src="/BRANDBOOK.png"
              alt=""
            />
            <Link to="/jobs">
              <img
                draggable={true}
                onDragStart={(e) => setDraggedImage(e.target)}
                onDragEnd={() => setIsDragging(false)}
                onClick={handleCloseClick}
                style={{
                  position: "absolute",
                  left: "340px",
                  top: "180px",
                  opacity: isDragging ? 0.5 : 1,
                  cursor: "pointer",
                }}
                width="120px"
                src="/JOBAPPLICATION.png"
                alt=""
              />
            </Link>
            <Link to="/selected-works">
              <img
                draggable={true}
                onDragStart={(e) => setDraggedImage(e.target)}
                onDragEnd={() => setIsDragging(false)}
                onClick={handleCloseClick}
                style={{
                  position: "absolute",
                  bottom: "100px",
                  opacity: isDragging ? 0.5 : 1,
                  cursor: "pointer",
                }}
                width="120px"
                src="/SELECTEDWORKS.png"
                alt=""
              />
            </Link>
            <button
              style={{
                fontFamily: "Neutria Regular",
                fontStyle: "normal",
                fontWeight: "200",
              }}
              onClick={handleCloseClick}
            >
              CLOSE
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
