import React, { useState } from "react";

function Card({ src, title, description, year, className }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        flex: "40%",
        padding: "20px",
      }}
      className={className}
    >
      <div
        className="cardentire"
        style={{
          position: "relative",
          overflow: "hidden",
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <div
          className={`redoverlay ${isHovered ? "slide-up" : ""}`}
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            height: "100%",
            background: "#FE322E",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "0.5s",
            animation: "ease-in-out",
            transform: isHovered ? "translateY(-100%)" : "translateY(0)",
          }}
        >
          <p
            className="redoverlaytext"
            style={{
              color: "white",
              fontSize: "20px",
              fontFamily: "Neutria Light",
            }}
          >
            View case
          </p>
        </div>
        <img style={{ width: "100%" }} src={src} alt="" />
      </div>
      <div
        className="cardentire"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="cardinfo"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h4
            className="gridtitle"
            style={{ fontSize: "22px", marginRight: "15px" }}
          >
            {title}
          </h4>
          <span
            className="griddescription"
            style={{ color: "#FE322E", fontSize: "25px" }}
          >
            {description}
          </span>
        </div>
        <div>
          <p className="gridyear" style={{ fontSize: "15px" }}>
            {year}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
