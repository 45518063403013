import React from "react";
import { useLocation, Link } from "react-router-dom";

function Footer() {
  const isMobile = window.innerWidth < 900;
  const location = useLocation();
  const isContactsPage = location.pathname === "/contacts"; // assumendo che "/contacts" sia il percorso per la tua pagina dei contatti
  const footerClass = isContactsPage ? "footer contacts" : "footer";

  return (
    <>
      {isMobile ? (
        <div
          className={isContactsPage ? "footer-contacts" : "footer"}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            marginBottom: "16px",
            zIndex: "20",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 25px",
            }}
          >
            {" "}
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://it.linkedin.com/company/livemefive"
            >
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                LINKEDIN
              </button>
            </a>
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.instagram.com/liveme5ive/"
            >
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                INSTAGRAM
              </button>
            </a>
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.behance.net/liveme5ive"
            >
              <button
                style={{
                  background: "white",
                  border: "none",
                  color: "#FE322E",
                }}
              >
                BEHANCE
              </button>
            </a>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <p
              style={{
                color: "#FE322E",
                fontFamily: "Neutria Regular !important",
                textAlign: "center",
                fontSize: "11px",
              }}
            >
              <span style={{ fontWeight: "", fontFamily: "Neutria Regular" }}>© LiveMeFive 2023</span> • All Rights
              Reserved • P.IVA 11259370960
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px",
            height: "60px",
            borderTop: "1px solid  #FE322E",
            borderBottom: "1px solid  #FE322E",
            marginBottom: "2px",

            width: "100%",
          }}
        >
          <div
            style={{
              width: "33.3%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "#FE322E", fontFamily: "Neutria Light" }}>
              <span style={{ fontWeight: "600" }}>© LiveMeFive 2023</span> • All Rights Reserved • P.IVA 11259370960
            </p>
          </div>
          <div
            style={{
              width: "33.3%",
              borderRight: "1px solid #FE322E",
              borderLeft: "1px solid #FE322E",
              height: "60px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {" "}
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://it.linkedin.com/company/livemefive"
            >
              <button
                style={{
                  background: "white",
                  outline: "none",
                  color: "#FE322E",
                  fontFamily: "Neutria Regular",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                LINKEDIN
              </button>
            </a>
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.instagram.com/liveme5ive/"
            >
              <button
                style={{
                  background: "white",
                  outline: "none",
                  color: "#FE322E",
                  fontFamily: "Neutria Regular",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                INSTAGRAM
              </button>
            </a>
            <a
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              href="https://www.behance.net/liveme5ive"
            >
              <button
                style={{
                  background: "white",
                  outline: "none",
                  color: "#FE322E",
                  fontFamily: "Neutria Light",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                BEHANCE
              </button>
            </a>
          </div>
          <div
            style={{
              width: "33.3%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "60px",
            }}
          >
            <img style={{ width: "50px" }} src="/META.png" alt="" />
            <img style={{ width: "35px", marginRight: "20px" }} src="/OUTBRAIN.png" alt="" />
            <img style={{ width: "32px", marginRight: "20px" }} src="/TIKTOK.png" alt="" />
            <img style={{ width: "40px" }} src="taboola.png" alt="" />
            <img style={{ width: "40px", marginRight: "20px" }} src="/GOOGLEPARTNER.png" alt="" />
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
