import React, { useState } from "react";
import Modal from "react-modal";

function TeamPopup(props) {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const buttons = [
    {
      imageSrc: "/Takingdef.jpg",
      width: "400px",
      title: "Taking it seriously.jpg",
      description: "Image JPG - 3,4 MB",
      info: [
        { label: "Created", value: "Mercoledì 28 Dicembre 2022, 14:36" },
        { label: "File Size", value: "5955 x 3238" },
        {
          label: "Tag",
          value:
            "Alessandro Manno; Alberto Amato; Adriana Tria; Lorenzo Beccaria ",
        },
      ],
    },
    {
      imageSrc: "/SHOOT_ALBERTOdef.jpg",
      width: "320px",
      title: "SHOOT ALBERTO.jpg",
      description: "Image JPG - 3,5 MB",
      info: [
        { label: "Created", value: "Monday 21 November 2022, 14:13" },
        { label: "File Size", value: "2730 × 2730" },
        { label: "Tag", value: "Alberto Amato" },
      ],
    },
    {
      imageSrc: "/SHOOT_ADRIANAdef.jpg",
      width: "320px",
      title: "SHOOT ADRIANA.jpg",
      description: "Image JPG - 1,8 MB ",
      info: [
        { label: "Created", value: "Monday 21 November 2022, 14:37" },
        { label: "File Size", value: "3102 × 3102" },
        { label: "Tag", value: "Adriana Tria" },
      ],
    },
    {
      imageSrc: "/SHOOT_ALESSANDROdef.jpg",
      width: "320px",
      title: "SHOOT ALESSANDRO.jpg",
      description: "Image JPG - 1,4 MB",
      info: [
        { label: "Created", value: "Monday 21 November 2022, 15:19" },
        { label: "File Size", value: "2826 × 2826" },
        { label: "Tag", value: "Alessandro Manno" },
      ],
    },
    {
      imageSrc: "/SHOOT_LORENZOdef.jpg",
      width: "320px",
      title: "SHOOT LORENZO.jpg",
      description: "Image JPG - 6,6 MB",
      info: [
        { label: "Created", value: "Monday 21 November 2022, 15:46" },
        { label: "File Size", value: "3600 × 3599" },
        { label: "Tag", value: "Lorenzo Beccaria" },
      ],
    },
    {
      imageSrc: "/Astepdef.jpg",
      width: "220px",
      title: "A step ahead.jpg",
      description: "Image JPG - 1,9 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:21" },
        { label: "File Size", value: "2265 × 3020" },
        {
          label: "Tag",
          value:
            "Valentina Cordilani; Matilda Hautant; Andrea Gulino; Alberto Amato",
        },
      ],
    },
    {
      imageSrc: "/Dont_get_lostdef.jpg",
      width: "220px",
      title: "Don't get lost.jpg",
      description: "Image JPG - 3,6 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:48" },
        { label: "File Size", value: "3024 × 4032" },
        {
          label: "Tag",
          value: "Valentina Cordinali; Matilda Hautant; Alberto Amato",
        },
      ],
    },
    {
      imageSrc: "/Enjoydef.jpg",
      width: "220px",
      title: "Enjoy the moment.jpg",
      description: "Image JPG - 4,2 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:12" },
        { label: "File Size", value: "3024 × 4032" },
        {
          label: "Tag",
          value:
            "Alessandro Manno; Matilda Hautant; Andrea Gulino; Alberto Amato ",
        },
      ],
    },
    {
      imageSrc: "/Kissdef.jpg",
      width: "220px",
      title: "Kissing and Smile.jpg",
      description: "Image JPG - 2,2 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 18:45" },
        { label: "File Size", value: "3024 × 4032" },
        {
          label: "Tag",
          value: "Alessandro Manno",
        },
      ],
    },
    {
      imageSrc: "/npsdef.jpg",
      width: "350px",
      title: "Nina_Pinta & Santa Maria.jpg",
      description: "Image JPG - 3,1 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 18:22" },
        { label: "File Size", value: "3575 × 2860" },
        {
          label: "Tag",
          value: "Adriana Tria; Matilda Hautant; Valentina Cordinali",
        },
      ],
    },
    {
      imageSrc: "/seekingdef.jpg",
      width: "220px",
      title: "Seeking yourself.jpg",
      description: "Image JPG - 3,3 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:31" },
        { label: "File Size", value: "3024 × 4032" },
        {
          label: "Tag",
          value: "Alberto Amato",
        },
      ],
    },
    {
      imageSrc: "/slowlydef.jpg",
      width: "220px",
      title: "Slowly but steady.jpg",
      description: "Image JPG - 1,2 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:55" },
        { label: "File Size", value: "2122 × 2829" },
        {
          label: "Tag",
          value: "Adriana Tria",
        },
      ],
    },
    {
      imageSrc: "/teamorfamilydef.jpg",
      width: "220px",
      title: "Team or Family.jpg",
      description: "Image JPG - 1,8 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 17:06" },
        { label: "File Size", value: "2316 × 3088" },
        {
          label: "Tag",
          value:
            "Adriana Tria; Valentina Cordilani; Matilda Hautant; Alessandro Manno; Andrea Gulino; Alberto Amato",
        },
      ],
    },
    {
      imageSrc: "/threeeyesdef.jpg",
      width: "220px",
      title: "Three pairs of eyes.jpg",
      description: "Image JPG - 529 KB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 18:10" },
        { label: "File Size", value: "2316 × 3088" },
        {
          label: "Tag",
          value: "Adriana Tria; Alessandro Manno; Valentina Cordilani",
        },
      ],
    },
    {
      imageSrc: "/wishdef.jpg",
      width: "220px",
      title: "Wish you were.jpg",
      description: "Image JPG - 2,9 MB",
      info: [
        { label: "Created", value: "Wednesday 15 March 2023, 18:29" },
        { label: "File Size", value: "3024 × 4032" },
        {
          label: "Tag",
          value: "Valentina Cordilani",
        },
      ],
    },
    // add more buttons here
  ];

  const handleButtonClick = (index) => {
    setSelectedButtonIndex(index);
  };

  const selectedButton = buttons[selectedButtonIndex];

  return (
    <Modal
      className="modal"
      isOpen={props.showTeamPopup}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          backgroundColor: "none",
          zIndex: "100000",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "#fbfbfb",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          height: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "20px",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={props.onClose}
            className="mac-window-button red"
          ></div>
          <div
            style={{ cursor: "pointer" }}
            className="mac-window-button yellow"
          ></div>
          <div
            style={{ cursor: "pointer" }}
            className="mac-window-button green"
          ></div>
        </div>
        <div style={{ marginLeft: "350px" }}>
          <p style={{ fontWeight: "600" }}>TEAM</p>
        </div>
      </div>
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            height: "96%",
            borderRight: "1px solid #d9d9d9",
            padding: "10px",
          }}
        >
          {buttons.map((button, index) => (
            <div>
              <button
                className="filebtn"
                key={index}
                onClick={() => handleButtonClick(index)}
                autoFocus={index === 0}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  style={{ width: "15px", marginRight: "7px" }}
                  src="/team-icon.png"
                />
                {button.title}
              </button>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            padding: "10px 25px",
          }}
        >
          <img
            style={{ borderRadius: "20px", width: selectedButton.width }}
            src={selectedButton.imageSrc}
            alt=""
          />
          <div>
            <p
              style={{
                fontWeight: "600",
                marginTop: "50px",
                fontFamily: "Neutria Light !important",
              }}
            >
              {selectedButton.title}
            </p>
            <p style={{ marginTop: "5px", color: "#9d9d9d" }}>
              {selectedButton.description}
            </p>
          </div>
          <div>
            <p
              style={{
                fontWeight: "600",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Information
            </p>
            {selectedButton.info.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #cdcdcd",
                  marginBottom: "10px",
                }}
              >
                <p
                  style={{
                    color: "#9d9d9d",
                    fontSize: "14px",
                    fontFamily: "Neutria Light",
                    fontWeight: "600",
                  }}
                >
                  {item.label}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TeamPopup;
