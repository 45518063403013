import React, { useState, useEffect } from "react";
import "./Job.css";
import Preloader from "../loadinganimations/Preloader";

function JobApplication() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Preloader />
      {!isMobile ? (
        <div class="job-application-container">
          <div class="semi-circle">
            <div style={{ marginBottom: "-300px" }} class="text-container">
              <h2
                className="jobtext"
                style={{
                  color: "#FE322E",
                  fontSize: "90px",
                  fontWeight: "500",
                  marginBottom: "18px",
                  fontFamily: "GT Light !important",
                  fontKerning: "normal",
                  letterSpacing: "-2.5px",
                  lineHeight: "90px",
                }}
              >
                <span style={{ fontFamily: "GT Light Italic" }}>
                  {" "}
                  Peekaboo!
                </span>
                <br></br> We are looking for You.
              </h2>
              <p
                style={{
                  fontSize: "20px",
                  marginBottom: "50px",
                  marginTop: "35px",
                  fontFamily: "Neutria Regular",
                }}
              >
                You've just got to be crazy enough, moderately eccentric,
                talented beyond measure, and insanely proactive
              </p>
              <a href="mailto:hello@livemefive.com?subject=Job Application at LiveMe5ive">
                <button
                  className="cta-btn2"
                  style={{
                    background: "#FE322E",
                    color: "white",
                    border: "none",
                    padding: "15px 70px",
                    cursor: "pointer",
                  }}
                >
                  JOIN US
                </button>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div class="job-application-container-mobile">
          <div class="semi-circle-mobile">
            <div style={{ marginBottom: "-695px" }} class="text-container">
              <h2
                className="jobtext"
                style={{
                  color: "#FE322E",
                  fontSize: "38px",
                  fontWeight: "500",
                  marginBottom: "18px",
                  letterSpacing: "-1px",
                  lineHeight: "38px",
                }}
              >
                <span style={{ fontFamily: "GT Light Italic" }}>
                  {" "}
                  Peekaboo!
                </span>
                <br></br> We are looking for You.
              </h2>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "50px",
                  fontFamily: "Neutria Light",
                  maxWidth: "350px",
                  color: "#575757",
                }}
              >
                You've just got to be crazy enough, moderately eccentric,
                talented beyond measure, and insanely proactive
              </p>
              <a href="mailto:hello@livemefive.com?subject=Job Application at LiveMe5ive">
                <button
                  className="cta-btn2"
                  style={{
                    background: "#FE322E",
                    color: "white",
                    border: "none",
                    padding: "15px 70px",
                    cursor: "pointer",
                  }}
                >
                  JOIN US
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default JobApplication;
