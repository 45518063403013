import React, { useState, useEffect, useRef } from 'react';
import './Eyes.css';

const Eye = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const eyeRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = e => {
      const { left, top } = eyeRef.current.getBoundingClientRect();
      const eyeWidth = eyeRef.current.offsetWidth;
      const eyeHeight = eyeRef.current.offsetHeight;
      const pupilWidth = eyeWidth * 0.2;
      const pupilHeight = eyeHeight * 0.2;
      const x = e.pageX - (left + eyeWidth / 2);
      const y = e.pageY - (top + eyeHeight / 2);
      const angle = Math.atan2(y, x);
      const radius = eyeWidth / 2 - pupilWidth / 2;
      const posX = Math.cos(angle) * radius;
      const posY = Math.sin(angle) * radius;
      const maxPos = radius - pupilWidth / 2;
      const distance = Math.sqrt(Math.pow(posX, 2) + Math.pow(posY, 2));
      if (distance > maxPos) {
        setPosition({
          x: posX * maxPos / distance,
          y: posY * maxPos / distance
        });
      } else {
        setPosition({
          x: posX,
          y: posY
        });
      }
    };
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="eyes">
      <div className="eye" ref={eyeRef}>
        <div className="pupil" style={{
          transform: `translate(${position.x}px, ${position.y}px)`
        }}></div>
      </div>
      <div className="eye" ref={eyeRef}>
        <div className="pupil" style={{
          transform: `translate(${position.x}px, ${position.y}px)`
        }}></div>
      </div>
    </div>
  );
};

export default Eye;

