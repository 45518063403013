import { React, useState, useEffect } from 'react';
import Preloader from '../loadinganimations/Preloader';
import './Contacts.css';

function Contacts() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Preloader />
      {!isMobile ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <h2
            style={{
              marginTop: '100px',
              fontSize: '100px',
              color: '#FE322E',
              maxWidth: '1050px',
              fontWeight: '500',
              margin: '0',
              textAlign: 'center',
              letterSpacing: '-2.5px',
              lineHeight: '100px',
            }}
          >
            How far do you want to take your{' '}
            <span style={{ fontFamily: 'GT Light Italic' }}>
              business <span style={{ fontFamily: 'GT Light' }}>?</span>
            </span>
          </h2>
          <p
            style={{
              maxWidth: '560px',
              fontSize: '20px',
              textAlign: 'center',
              margin: '30px 0 ',
              fontFamily: 'Neutria Regular',
              color: '#575757',
            }}
          >
            Together, we break free from the ‘this is how it’s done’ boundaries
            and unleash the infinite potential within and beyond your reality.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '50px 0',
            }}
          >
            <p
              style={{
                marginRight: '120px',
                fontFamily: 'Neutria Light',
                color: '#575757',
              }}
            >
              <span style={{ color: '#FE322E', fontFamily: 'Neutria Regular' }}>
                Viale Bianca Maria 13, Milan 20122
              </span>{' '}
              | Legal Address
            </p>
            <p style={{ fontFamily: 'Neutria Light', color: '#575757' }}>
              <span style={{ color: '#FE322E', fontFamily: 'Neutria Regular' }}>
                Via Pitteri 10 Milan, 20134
              </span>{' '}
              | Operational Headquarter
            </p>
          </div>
          <a href='mailto:hello@livemefive.com?subject=Info Request at LiveMe5ive'>
            <button
              style={{
                background: '#FE322E',
                color: 'white',
                border: 'none',
                padding: '15px 70px',
                cursor: 'pointer',
              }}
            >
              WRITE US
            </button>
          </a>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',

            alignItems: 'center',
            flexDirection: 'column',

            padding: '0 20px',
            marginTop: '170px',
          }}
        >
          <h2
            style={{
              fontSize: '40px',
              color: '#FE322E',
              maxWidth: '1050px',
              fontWeight: '500',
              margin: '0',
              textAlign: 'center',
              lineHeight: '1',
            }}
          >
            How far do you want to take your{' '}
            <span style={{ fontStyle: 'italic' }}>business?</span>
          </h2>
          <p
            style={{
              maxWidth: '560px',
              fontSize: '16px',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            Together, we break free from the ‘this is how it’s done’ boundaries
            and unleash the infinite potential within and beyond your reality.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '20px 0',
              flexDirection: 'column',
            }}
          >
            <p style={{ marginBottom: '15px', fontSize: '12px' }}>
              <span style={{ color: '#FE322E' }}>
                Viale Bianca Maria 13, Milan 20122
              </span>{' '}
              | Legal Address
            </p>
            <p style={{ fontSize: '12px' }}>
              <span style={{ color: '#FE322E' }}>
                Via Pitteri 10 Milan, 20134
              </span>{' '}
              | Operational Headquarter
            </p>
          </div>
          <div
            className='shape'
            style={{
              position: 'absolute',
              background: '#FE322E',
              width: '120%',
              height: '35%',
              bottom: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderTopLeftRadius: '50% 100%',
              borderTopRightRadius: '50% 100%',
              overflow: 'hidden',
            }}
          >
            <a href='mailto:hello@livemefive.com?subject=Info Request at LiveMe5ive'>
              <button
                style={{
                  background: '#FE322E',
                  color: 'white',
                  border: '2px solid white',
                  padding: '15px 70px',
                  cursor: 'pointer',
                }}
              >
                WRITE US
              </button>
            </a>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '50%',
                background: '#FE322E',
                borderRadius: '50% 50% 0 0', // add this line
                transform: 'translateY(50%)', // add this line
                zIndex: -1, // add this line
                pointerEvents: 'none', // add this line
                content: '', // add this line
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Contacts;
