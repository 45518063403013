import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Job.css";
import Preloader from "../loadinganimations/Preloader";

function MethodCta() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [isMobileMid, setIsMobileMid] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 900);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div class="job-application-container">
          <div class="semi-circle">
            <div style={{ marginBottom: "-300px" }} class="text-container">
              <h2
                className="jobtext"
                style={{
                  color: "#FE322E",
                  fontSize: "90px",
                  fontWeight: "500",
                  marginBottom: "18px",
                  fontFamily: "GT Light !important",
                  fontKerning: "normal",
                  letterSpacing: "-2.5px",
                  lineHeight: "90px",
                }}
              >
                Take a moment and <br></br>
                <span style={{ fontFamily: "GT Light Italic" }}>
                  experience
                </span>{" "}
                our Works
              </h2>
              <Link to="/selected-works">
                <button
                  className="cta-btn2"
                  style={{
                    background: "#FE322E",
                    color: "white",
                    border: "none",
                    padding: "15px 70px",
                    cursor: "pointer",
                  }}
                >
                  EXPLORE
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="job-application-container-mobile">
          <div className="semi-circle-mobile">
            <div style={{ marginBottom: "-695px" }} class="text-container">
              <h2
                className="jobtext"
                style={{
                  color: "#FE322E",
                  fontSize: "38px",
                  fontWeight: "500",
                  marginBottom: "18px",
                  letterSpacing: "-1px",
                  lineHeight: "38px",
                }}
              >
                Take a moment and <br></br>
                <span style={{ fontFamily: "GT Light Italic" }}>
                  experience
                </span>{" "}
                our works
              </h2>
              <Link to="/selected-works">
                <button
                  className="cta-btn2"
                  style={{
                    background: "#FE322E",
                    color: "white",
                    border: "none",
                    padding: "15px 70px",
                    cursor: "pointer",
                  }}
                >
                  EXPLORE
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MethodCta;
